import styled from '@emotion/styled'
import { BannerCTA } from 'app/components/BannerCTA'
import { ExtraList } from 'app/components/ExtraList'
import { Intro } from 'app/components/Intro'
import { MeetingRooms } from 'app/components/MeetingRooms'
import { SEO } from 'app/components/SEO'
import { ServicesList } from 'app/components/ServicesList'
import {
  Props as StructuredDataProps,
  StructuredData,
} from 'app/components/StructuredData'
import { CongressForm } from 'app/containers/CongressForm'
import { Footer, Props as FooterProps } from 'app/containers/Footer'
import { Header, Props as HeaderProps } from 'app/containers/Header'
import {
  CongressFormSenderBackend,
  CongressFormSenderBackendConfiguration,
} from 'app/utils/CongressFormSender'
import { PageProps } from 'gatsby'
import React, { memo, useMemo } from 'react'

import { Props } from './contents'

export interface PageContext {
  id: string
  languageCode: string
  languagePrefix: string | null
  headerProps?: HeaderProps
  footerProps?: FooterProps
  structuredDataProps?: StructuredDataProps
  congressFormSenderBackendConfiguration: CongressFormSenderBackendConfiguration
}

export interface Context extends PageContext {
  props: Props
}

export default memo(function CongressPageTemplate({
  pageContext,
}: PageProps<void, Context>) {
  const context = pageContext as any

  const sendFormToBackend = useMemo(
    () =>
      CongressFormSenderBackend(
        pageContext.congressFormSenderBackendConfiguration,
      ),
    [pageContext.congressFormSenderBackendConfiguration],
  )

  return (
    <Container>
      <SEO {...context.seoProps} />
      {context.structuredDataProps ? (
        <StructuredData
          languageCode={pageContext.languageCode}
          {...context.structuredDataProps}
        />
      ) : null}
      {context.headerProps ? (
        <Header
          pageID={context.id}
          languageCode={context.languageCode}
          languagePrefix={context.languagePrefix}
          {...context.headerProps}
        />
      ) : null}
      {context.introProps ? <Intro {...context.introProps} /> : null}
      {context.meetingRoomsProps ? (
        <MeetingRooms {...context.meetingRoomsProps} />
      ) : null}
      {context.servicesListProps ? (
        <ServicesList {...context.servicesListProps} />
      ) : null}
      {context.extraListProps ? (
        <ExtraList {...context.extraListProps} />
      ) : null}
      {context.formProps ? (
        <CongressForm
          {...context.formProps}
          onCongressFormSendToBackend={async (data) =>
            await sendFormToBackend(data)
          }
        />
      ) : null}
      {context.bannerCTAProps ? (
        <BannerCTA {...context.bannerCTAProps} />
      ) : null}
      {context.footerProps ? (
        <Footer languageCode={context.languageCode} {...context.footerProps} />
      ) : null}
    </Container>
  )
})

const Container = styled.main``
