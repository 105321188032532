import styled from '@emotion/styled'
import { createState, State, useState as HookState } from '@hookstate/core'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { Resize, Users } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { useVocabularyData } from 'app/utils/vocabulary'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'
import { Link } from 'react-scroll'

export interface Props {
  image?: ImageProps
  languageCode: string
  map?: string
  pax?: string
  services?: string[]
  size?: string
  title?: string
}

const globalState = createState('')
const wrapState = (s: State<string>) => ({
  get: () => s.value,
  set: (p: any) => s.set(() => p),
})

export const accessGlobalState = () => wrapState(globalState)
export const useGlobalState = () => wrapState(HookState(globalState))

export const Room = memo(function Room({
  image,
  languageCode,
  map,
  pax,
  services,
  size,
  title,
}: Props) {
  return (
    <Container>
      <Background className="room-bg">
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      <Wrapper row wrap>
        <LeftSide>
          {title ? (
            <Title>
              <Line />
              {title}
            </Title>
          ) : null}

          {pax || size ? (
            <Info row>
              {pax ? (
                <Pax dial={4} row>
                  <Users />
                  {pax}
                </Pax>
              ) : null}

              {size ? (
                <Size dial={4} row>
                  <Resize />
                  {size}
                </Size>
              ) : null}
            </Info>
          ) : null}
        </LeftSide>

        <RightSide>
          {services
            ? services.map((item: any, index) => (
                <Service key={index}>{item.text}</Service>
              ))
            : null}
        </RightSide>
      </Wrapper>

      <Buttons dial={4} row>
        <CTA
          to="section-form"
          offset={-100}
          onClick={() => {
            accessGlobalState().set(title)
          }}
          smooth
        >
          {useVocabularyData('book-room', languageCode)}
        </CTA>
        {map ? (
          <Map href={map} target="_blank" rel="noopener">
            {useVocabularyData('download-the-map', languageCode)}
          </Map>
        ) : null}
      </Buttons>
    </Container>
  )
})

const Container = styled.div`
  width: calc(50% - 4.625rem);
  margin: 5.3125rem 4.625rem 0 0;
  &:first-of-type {
    width: calc(100% - 4.625rem);
    margin: 0;

    .room-bg {
      padding-bottom: 40%;
    }
  }

  @media (max-width: 1199px) {
    width: calc(100% - 4.625rem);
    margin-right: 0;

    .room-bg {
      padding-bottom: 40%;
    }
  }

  @media (max-width: 767px) {
    width: 100%;

    &:first-of-type {
      width: 100%;

      .room-bg {
        padding-bottom: 80%;
      }
    }

    .room-bg {
      padding-bottom: 80%;
    }
  }
`

const Background = styled.div`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  margin-bottom: 2.5rem;
  padding-bottom: 75%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }
`

const Wrapper = styled(FlexBox)``

const LeftSide = styled.div`
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
  }
`

const Title = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.125rem;

  > div {
    margin-bottom: 0.75rem;
  }
`

const Info = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  line-height: 2.125rem;
  margin-top: 1.375rem;

  > div {
    &:nth-of-type(2) {
      margin-left: 1.875rem;
    }
  }

  svg {
    width: auto;
    height: 1.25rem;
    fill: ${({ theme }) => theme.colors.variants.neutralDark2};
    margin-right: 0.625rem;
  }

  @media (max-width: 1023px) {
    margin-top: 0.875rem;
  }
`

const Pax = styled(FlexBox)``

const Size = styled(FlexBox)``

const RightSide = styled.div`
  width: 50%;

  @media (max-width: 767px) {
    width: 100%;
    margin-top: 1.875rem;
  }
`

const Service = styled.div`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.625rem;
  padding-left: 1.25rem;
  position: relative;
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    border-radius: 50%;
    position: absolute;
    top: 0.625rem;
    left: 0;
  }
`

const Buttons = styled(FlexBox)`
  margin-top: 2.5rem;

  @media (max-width: 767px) {
    flex-wrap: wrap;

    a {
      width: 100%;
    }
  }
`

const CTA = styled(Link)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 3.75rem;
  border: 0.125rem solid ${({ theme }) => theme.colors.variants.neutralDark2};
  border-radius: 1.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  cursor: pointer;
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  padding: 0 2.5rem;
  transition: 0.2s ease-in-out;
  &:hover {
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }
`

const Map = styled.a`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.875rem;
  margin-left: 1.875rem;
  text-decoration: underline;

  @media (max-width: 767px) {
    margin-top: 1rem;
    margin-left: 0;
    text-align: center;
  }
`
