import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import React, { memo } from 'react'
import { useInView } from 'react-intersection-observer'

export interface Props {
  text?: string
}

export const Service = memo(function Service({ text }: Props) {
  if (!text) {
    return null
  }

  const [ref, isVisible] = useInView({ threshold: 0.25 })
  const variants = {
    visible: {
      duration: 0.6,
      opacity: 1,
      y: 0,
    },
    hidden: {
      opacity: 0,
      y: '3.75rem',
    },
  }

  return (
    <Container
      ref={ref}
      animate={isVisible ? 'visible' : 'hidden'}
      transition={{
        duration: 0.5,
      }}
      variants={variants}
    >
      {text}
    </Container>
  )
})

const Container = styled(motion.div)`
  width: 50%;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0.75rem;
  padding-left: 1.25rem;
  position: relative;
  &:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    background: ${({ theme }) => theme.colors.variants.neutralDark2};
    border-radius: 50%;
    position: absolute;
    top: 0.5rem;
    left: 0;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`
