import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Service } from 'app/components/ServicesList/Service'
import React, { memo } from 'react'

export interface Props {
  services: string[]
  title?: string
}

export const ExtraList = memo(function ServicesList({
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Line />
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      <Services row wrap>
        {services.map((item, index) => (
          <Service key={index} {...item} />
        ))}
      </Services>
    </Container>
  )
})

const Container = styled.section`
  margin: 150px auto 200px;
  padding: 0 10vw;

  @media (max-width: 1023px) {
    margin-top: 5.3125rem;
    margin-bottom: 5.3125rem;
    padding: 0 1.3125rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.125rem;
`

const Services = styled(FlexBox)`
  margin-top: 3.125rem;

  > div {
    width: 33.333%;
    margin-top: 1.875rem;
    padding-right: 10vw;
  }

  @media (max-width: 1023px) {
    margin-top: 2.5rem;

    > div {
      width: 50%;
      margin-top: 0.75rem;
      padding-right: 1.3125rem;
    }
  }

  @media (max-width: 767px) {
    > div {
      width: 100%;
      padding-right: 0;
    }
  }
`
