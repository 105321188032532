import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Service } from './Service'

export interface Props {
  image?: ImageProps
  services: string[]
  title?: string
}

export const ServicesList = memo(function ServicesList({
  image,
  services,
  title,
}: Props) {
  if (services.length < 1) {
    return null
  }

  return (
    <Container row tag="section" stretch wrap>
      <LeftSide>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </LeftSide>

      <RightSide dial={4}>
        {title ? (
          <FadeInUp>
            <Line />
            <Title>{title}</Title>
          </FadeInUp>
        ) : null}

        <Services row space="between" wrap>
          {services.map((item, index) => (
            <Service key={index} {...item} />
          ))}
        </Services>
      </RightSide>
    </Container>
  )
})

const Container = styled(FlexBox)`
  margin: 6.5rem auto 9.3125rem;

  @media (max-width: 1023px) {
    margin-top: 5.3125rem;
    margin-bottom: 5.3125rem;
  }
`

const LeftSide = styled.div`
  width: 33.333%;
  padding-bottom: 42%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightSide = styled(FlexBox)`
  width: 66.666%;
  padding: 0 10.764vw;

  @media (max-width: 1023px) {
    width: 100%;
    padding: 0 1.3125rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.875rem;
  line-height: 2.125rem;
`

const Services = styled(FlexBox)`
  margin-top: 5rem;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
  }
`
