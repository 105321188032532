import styled from '@emotion/styled'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Props as RoomProps, Room } from './Room'

export interface Props {
  rooms: RoomProps[]
}

export const MeetingRooms = memo(function MeetingRooms({ rooms }: Props) {
  if (!rooms) {
    return null
  }

  return (
    <Container>
      <Wrapper row wrap>
        {rooms.map((item, index) => (
          <Room key={index} {...item} />
        ))}
      </Wrapper>
    </Container>
  )
})

const Container = styled.section`
  margin: 9.375rem auto 10.25rem;
  padding: 0 10vw;

  @media (max-width: 1023px) {
    margin-top: 8.5rem;
    margin-bottom: 8.5rem;
    padding: 0 1.3125rem;
  }
`

const Wrapper = styled(FlexBox)`
  margin-right: -4.625rem;

  @media (max-width: 767px) {
    margin-right: 0;
  }
`
