import styled from '@emotion/styled'
import { Checkbox } from 'app/components/Common/Checkbox'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { FormMessages } from 'app/components/Common/FormMessages'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Input } from 'app/components/Common/Input'
import { Select } from 'app/components/Common/Select'
import { Spinner } from 'app/components/Common/Spinner'
import { Textarea } from 'app/components/Common/Textarea'
import { FlexBox } from 'app/components/Layout/FlexBox'
import {
  accessGlobalState,
  useGlobalState,
} from 'app/components/MeetingRooms/Room'
import { theme } from 'app/theme'
import {
  CongressFormSenderBackend,
  FormData,
} from 'app/utils/CongressFormSender'
import { useVocabularyData } from 'app/utils/vocabulary'
import { enUS, it } from 'date-fns/esm/locale'
import { rgba } from 'emotion-rgba'
import { isLeft } from 'fp-ts/lib/These'
import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import DatePicker, { registerLocale } from 'react-datepicker'
import { useForm } from 'react-hook-form'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

registerLocale('en', enUS)
registerLocale('it', it)

export interface Props {
  languageCode: string
  languagePrefix?: string | null
  image?: ImageProps
  onCongressFormSendToBackend: CongressFormSenderBackend
  rooms?: string[]
  subtitle?: string
  title?: string
}

export const CongressForm = memo(function CongressForm({
  languageCode,
  languagePrefix,
  image,
  onCongressFormSendToBackend,
  rooms,
  subtitle,
  title,
}: Props) {
  /*
   * Datepicker configuration
   */
  const [date, setDate] = useState<any>()

  /*
   * Form data
   */
  const selectedTreatment = useGlobalState()
  const roomRef = useRef<HTMLSelectElement>(null)

  const [isSendingForm, setIsSendingForm] = useState(false)
  const [displaySendErrorFeedback, setDisplaySendErrorFeedback] =
    useState(false)
  const [displaySendConfirmFeedback, setDisplaySendConfirmFeedback] =
    useState(false)

  const onValidSubmission = useCallback(
    async (data: FormData) => {
      if (isSendingForm) {
        return
      }

      setDisplaySendErrorFeedback(false)
      setDisplaySendConfirmFeedback(false)
      setIsSendingForm(true)
      const sendResult = await onCongressFormSendToBackend(data)
      setIsSendingForm(false)

      if (isLeft(sendResult)) {
        setDisplaySendErrorFeedback(true)
      } else {
        setDisplaySendConfirmFeedback(true)

        typeof window.gtag !== 'undefined' &&
          window.gtag('event', 'Submit', {
            event_category: 'Website',
            event_label: 'Congress Form',
          })
      }
    },
    [isSendingForm, onCongressFormSendToBackend],
  )

  const { register, formState, handleSubmit, setValue, trigger } =
    useForm<FormData>({
      mode: 'onBlur',
      shouldFocusError: true,
    })

  const onSubmit = useMemo(
    () => handleSubmit(onValidSubmission),
    [handleSubmit, onValidSubmission],
  )

  /**
   * Handle scrolling to first field with errors
   */

  useEffect(() => {
    if (formState.errors) {
      const firstErrorElement = Object.values(formState.errors).find(
        (fieldError) => fieldError?.ref !== undefined,
      )?.ref as HTMLElement | undefined

      if (firstErrorElement && firstErrorElement.scrollIntoView) {
        firstErrorElement.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        })
      }
    }

    if (roomRef) {
      setValue('room', roomRef.current?.value)
      trigger('room')
    }
  }, [formState.errors, roomRef.current?.value, selectedTreatment.get()])

  return (
    <Container>
      {image ? (
        <Background>
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        </Background>
      ) : null}

      {title ? (
        <FadeInUp>
          <Title>{title}</Title>
        </FadeInUp>
      ) : null}

      {subtitle ? (
        <FadeInUp>
          <Subtitle>{subtitle}</Subtitle>
        </FadeInUp>
      ) : null}

      <Form
        id="section-form"
        onSubmit={isSendingForm ? undefined : onSubmit}
        noValidate
        autoComplete={'off'}
      >
        <Spinner className={isSendingForm ? '' : 'hidden'} variant="dark" />
        <Wrapper row wrap space="between">
          <Field>
            <Input
              error={formState.errors.name !== undefined}
              isDirty={formState.dirtyFields.name}
              placeholder={`${useVocabularyData('name', languageCode)}*`}
              type="text"
              required={true}
              {...register('name', {
                required: true,
                validate: (name) => name.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.lastname !== undefined}
              isDirty={formState.dirtyFields.lastname}
              placeholder={`${useVocabularyData('lastname', languageCode)}*`}
              type="text"
              required={true}
              {...register('lastname', {
                required: true,
                validate: (lastname) => lastname.length > 2,
              })}
            />
          </Field>
          <Field>
            <Input
              placeholder={useVocabularyData('email', languageCode)}
              type="email"
              {...register('email')}
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.phone !== undefined}
              isDirty={formState.dirtyFields.phone}
              type="text"
              placeholder={`${useVocabularyData('phone', languageCode)}*`}
              {...register('phone', {
                required: true,
                validate: (phone) => phone.length > 2,
              })}
            />
          </Field>
          <Field>
            <DatePicker
              className={
                formState.errors.date !== undefined && !date
                  ? 'error'
                  : undefined
              }
              dateFormat="dd/MM/yyyy"
              disabledKeyboardNavigation={true}
              locale={languagePrefix || 'it'}
              {...register('date', {
                required: true,
              })}
              onChange={(date) => {
                if (date) {
                  setDate(date)
                }
              }}
              onFocus={(e) => (e.target.readOnly = true)}
              placeholderText={`${useVocabularyData('date', languageCode)}*`}
              minDate={new Date()}
              required={true}
              selected={date}
              withPortal={
                typeof window !== 'undefined' && window.innerWidth > 767
                  ? false
                  : true
              }
            />
          </Field>
          <Field>
            <Input
              error={formState.errors.pax !== undefined}
              isDirty={formState.dirtyFields.pax}
              placeholder={`${useVocabularyData('pax', languageCode)}*`}
              type="text"
              required={true}
              {...register('pax', {
                required: true,
                validate: (pax) => pax.length > 1,
              })}
            />
          </Field>
          {rooms ? (
            <Field className="large-field">
              <Select
                ref={roomRef}
                options={[
                  {
                    value: '',
                    label: useVocabularyData('meeting-room', languageCode),
                    hidden: true,
                  },
                  ...rooms.map((e) => {
                    return {
                      value: e,
                    }
                  }),
                ]}
                name="room"
                onChange={(e) => {
                  selectedTreatment.set(e.target.value)
                }}
                value={selectedTreatment.get()}
              />
            </Field>
          ) : null}
          <Field className="large-field">
            <Textarea
              placeholder={useVocabularyData('message', languageCode)}
              required={false}
              {...register('message')}
            />
          </Field>
          <Checkbox
            error={formState.errors.privacy_policy !== undefined}
            label={useVocabularyData('form-policy-text', languageCode)}
            required={true}
            {...register('privacy_policy', {
              required: true,
            })}
          />
        </Wrapper>

        {displaySendErrorFeedback ? (
          <FormMessages
            text={useVocabularyData('form-error-message', languageCode)}
            title={useVocabularyData('form-error-message-title', languageCode)}
            type="error"
          />
        ) : null}
        {displaySendConfirmFeedback ? (
          <FormMessages
            text={useVocabularyData('form-confirmation-message', languageCode)}
            title={useVocabularyData(
              'form-confirmation-message-title',
              languageCode,
            )}
          />
        ) : null}

        <Input
          name="submit"
          type="submit"
          value={useVocabularyData('request-info', languageCode)}
          variant="submit"
        />
      </Form>
    </Container>
  )
})

const Container = styled.section`
  margin-top: 13.4375rem;
  padding: 0 10vw 11.875rem 10vw;
  position: relative;
  text-align: center;

  @media (max-width: 1023px) {
    margin-top: 7.5rem;
    padding: 0;
  }
`

const Background = styled.div`
  width: 100%;
  height: 50%;
  position: absolute;
  bottom: 0;
  left: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3.125rem;
  line-height: 3.75rem;

  @media (max-width: 1023px) {
    font-size: 2.1875rem;
    line-height: 2.8125rem;
    padding: 0 1.3125rem;
  }
`

const Subtitle = styled.h3`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  letter-spacing: 0.0938rem;
  line-height: 1.1875rem;
  margin-top: 0.75rem;
  text-transform: uppercase;

  @media (max-width: 1023px) {
    margin-top: 0.375rem;
    padding: 0 1.3125rem;
  }
`

const Form = styled.form`
  background: ${({ theme }) => theme.colors.variants.primaryDark};
  margin-top: 3.125rem;
  padding: 4.5rem 7.292vw;
  position: relative;
  z-index: 2;

  @media (max-width: 1023px) {
    margin-top: 2.5rem;
    padding: 1.875rem 1.3125rem 3.75rem;
  }
`

const Wrapper = styled(FlexBox)`
  text-align: left;
  &.stay-data {
    margin-top: 5.625rem;
  }

  .react-datepicker {
    font-family: ${({ theme }) => theme.fontFamily.paragraph};
    background-color: ${({ theme }) => theme.colors.variants.primaryLight};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__portal {
    display: flex;
    align-items: center;
    justify-content: center;
    background: ${rgba(theme.colors.variants.neutralDark1, 0.7)};
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
  }

  .react-datepicker__current-month {
    font-family: ${({ theme }) => theme.fontFamily.heading};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day:hover,
  .react-datepicker__day--selected {
    background-color: ${({ theme }) => theme.colors.variants.secondaryDark};
    color: ${({ theme }) => theme.colors.variants.neutralLight4};
  }

  .react-datepicker__day:focus {
    background-color: ${({ theme }) =>
      theme.colors.variants.secondaryDark} !important;
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
  }

  .react-datepicker__day--today {
    &:after {
      background: ${({ theme }) => theme.colors.variants.secondaryDark};
    }
  }

  .react-datepicker__day--disabled {
    color: ${({ theme }) => theme.colors.variants.neutralLight4} !important;
    opacity: 0.3;
  }

  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    height: 100%;
    position: relative;
    bottom: auto;
    left: auto;
    input {
      width: 100%;
      height: 3.75rem;
      background: url('/triangle-down.svg') no-repeat right center;
      border-bottom: 0.125rem solid
        ${({ theme }) => theme.colors.variants.neutralLight4};
      color: ${({ theme }) => theme.colors.variants.neutralLight4};
      cursor: pointer;
      font-family: ${({ theme }) => theme.fontFamily.paragraph};
      font-size: 0.9375rem;
      &.error {
        border-bottom-color: ${({ theme }) =>
          theme.colors.variants.dangerLight};
      }

      &::-webkit-input-placeholder {
        color: ${theme.colors.variants.neutralLight4};
        opacity: 1;
      }
      &::-moz-placeholder {
        color: ${theme.colors.variants.neutralLight4};
        opacity: 1;
      }
      &::-ms-input-placeholder {
        color: ${theme.colors.variants.neutralLight4};
        opacity: 1;
      }
      &::placeholder {
        color: ${theme.colors.variants.neutralLight4};
        opacity: 1;
      }
    }
  }
`

const Field = styled.div`
  width: calc(50% - 1.875rem);
  margin-top: 1.875rem;
  &.large-field {
    width: 100%;
  }

  @media (max-width: 1023px) {
    width: calc(50% - 0.75rem);
  }
`
